import { mergeProps } from '@/utils/mergeProps';
import { Navigation } from '../Navigation';
import { NavigationLinkProps } from '../Navigation.Link';

type BarMenuProps = { links: NavigationLinkProps[] };

export const getNavigationBarMenu = ({ links }: BarMenuProps) => {
  return links.map((link, index) => {
    return (
      <Navigation.Link
        key={index}
        {...mergeProps(
          {
            options: {
              colors: 'bar',
              size: 'bar',
              variant: 'bar',
            },
          },
          link,
        )}
      />
    );
  });
};
