import { tw } from '@/utils/tw';
import BaseTextFieldTheme from 'base/components/TextField/theme';

const Icon = tw.theme({
  extend: BaseTextFieldTheme.Icon,
  base: `text-black`,
  variants: {
    secondary: {
      true: `h-5 w-5`,
    },
  },
});

const TextField = tw.theme({
  extend: BaseTextFieldTheme,
  slots: {
    label: `text-gray-900 group-data-[status=error]:text-error-500`,
    input: `focus:ring-logga-600 text-body-md outline-offset-0 ring-gray-600 placeholder:text-gray-600 group-data-[status=error]:bg-white group-data-[status=error]:outline-0 group-data-[status=error]:outline-error-100 group-data-[status=error]:ring-1 group-data-[status=error]:ring-error-500 group-data-[status=error]:placeholder:text-black hover:placeholder:text-black group-data-[status=error]:hover:outline-2 group-data-[status=error]:hover:ring-error-500 focus:bg-white focus:outline-0 focus:ring-1 focus:placeholder:text-black group-data-[status=error]:focus:outline-2`,
    caption: `text-body-sm text-gray-800 group-data-[status=error]:text-error-500`,
  },
  variants: {
    variant: {
      primary: {
        group: `w-full gap-y-1.5`,
        label: `text-body-sm`,
      },
      secondary: {
        label: `text-body-md group-focus-within:text-body-sm group-data-[filled]:text-body-sm`,
      },
    },
  },
});

export default Object.assign(TextField, { Icon });
