import { camelToKebab, CamelToKebabCase } from '@/utils/camelToKebab';

export interface TrackingProps {
  id?: string;
  name?: string;
  creative?: string;
  object?: string;
  objectName?: string;
  objectExtraInfo?: string | object;
  objectUrl?: string;
  label?: string | object;
}

type TrackingAttributes = {
  [K in keyof TrackingProps as `data-cts-${K extends string ? CamelToKebabCase<K> : never}`]: string;
};

export const withTrackingAttributes = (props: TrackingProps): TrackingAttributes => {
  return Object.keys(props).reduce((current, key) => {
    const ctsName = `data-cts-${camelToKebab(key)}` as keyof TrackingAttributes;
    const trackingValue = props[key as keyof TrackingProps];

    switch (typeof trackingValue) {
      case 'object':
        current[ctsName] = JSON.stringify(trackingValue);
        break;
      default:
        current[ctsName] = String(trackingValue);
        break;
    }

    return current;
  }, {} as TrackingAttributes);
};
