/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_primary": "text-white border-logga-600 bg-logga-600 hover:bg-logga-700 hover:border-logga-700 focus:bg-logga-600 focus:border-logga-600 active:bg-logga-800 active:border-logga-800",
  "colors_secondary": "bg-white border-logga-600 text-logga-600 hover:bg-logga-300 focus:bg-white active:bg-logga-400",
  "size_small": "px-3 gap-1 h-7.5 text-button-sm",
  "size_medium": "px-4 gap-1.5 h-9 text-button-md",
  "size_large": "px-6 gap-1.5 h-11.5 text-button-md",
  "variant_default": "border inline-flex items-center justify-center rounded-full outline-none focus:outline-none transition-all duration-200 ease-in-out"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Button");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;