import { Accordion } from '@/components/Accordion';
import { useAppState } from '@/hooks/useAppState';
import { componentTheme } from '@/styles/Navigation';
import { mergeProps } from '@/utils/mergeProps';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { ReactNode } from 'react';
import { Navigation } from '../Navigation';
import { NavigationLinkProps } from '../Navigation.Link';
import { NavigationMenuProps } from '../Navigation.Menu';

const getTrackingAttributes = ({
  name,
  url,
  addGamesCampaign,
}: {
  name?: ReactNode;
  url?: string;
  addGamesCampaign?: boolean;
}) =>
  withTrackingAttributes({
    object: 'menu',
    creative: 'menu',
    objectName: name?.toString(),
    name: name?.toString(),
    objectUrl: url,
    objectExtraInfo: [{ type: 'main_navigation_menu', ...(addGamesCampaign ? { campaign: 'keesing_games' } : {}) }],
  });

type DrawerMenuLink = NavigationLinkProps | DrawerMenuLink[];

type DrawerMenuLinks = { links: DrawerMenuLink[]; depth?: number; options?: NavigationLinkProps['options'] };

const getNavigationDrawerMenuLinks = ({ links, depth = 1, options }: DrawerMenuLinks) => {
  const [{ gamesUrls }] = useAppState();

  return links.map((item, index) => {
    if (Array.isArray(item)) {
      const [title, ...links] = item;

      if (Array.isArray(title)) {
        getNavigationDrawerMenuLinks({ links: title, depth: depth + 1, options });
      } else {
        const isLink = typeof title.href !== 'undefined';

        const addGamesCampaign = gamesUrls?.includes(title.href ?? '');

        return (
          <Accordion
            key={`${depth}-${index}`}
            title={
              <Navigation.Link
                data-depth={depth}
                {...mergeProps(
                  {
                    options: {
                      colors: 'drawer',
                      size: 'drawer',
                      variant: 'drawerPrimary',
                      className: isLink ? 'cts-impression-item' : '',
                      ...options,
                    },
                    ...(isLink
                      ? getTrackingAttributes({ name: title.content, url: title.href, addGamesCampaign })
                      : {}),
                  },
                  title,
                )}
              />
            }
            content={getNavigationDrawerMenuLinks({
              links,
              depth: depth + 1,
              options,
            })}
            options={{ theme: componentTheme('accordion') }}
          />
        );
      }

      return;
    }

    const addGamesCampaign = gamesUrls?.includes(item.href ?? '');

    return (
      <Navigation.Link
        key={index}
        data-depth={depth}
        {...mergeProps(
          {
            options: {
              colors: 'drawer',
              size: 'drawer',
              variant: depth === 1 ? 'drawerPrimary' : 'drawerSecondary',
              className: 'cts-impression-item',
              ...options,
            },
            ...getTrackingAttributes({ name: item.content, url: item.href, addGamesCampaign }),
          },
          item,
        )}
      />
    );
  });
};

type DrawerMenuGroup = NavigationMenuProps & { links: DrawerMenuLink[] };

type DrawerMenu = {
  toggle?: React.ReactNode;
  menu: DrawerMenuGroup[];
  options?: {
    $menu?: NavigationMenuProps;
    $link?: NavigationLinkProps['options'];
  };
};

export const getNavigationDrawerMenu = ({ toggle, menu, options }: DrawerMenu) => {
  return {
    toggle: toggle ?? <Navigation.Drawer.Toggle />,
    menu: menu?.map(({ links, ...menu }, index) => (
      <Navigation.Menu key={index} size="drawer" variant="drawer" {...options?.$menu} {...menu}>
        {getNavigationDrawerMenuLinks({ links, options: options?.$link })}
      </Navigation.Menu>
    )),
  };
};
