/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_plain": "border-white bg-white text-logga-500 hover:bg-gray-100 hover:border-gray-100 focus:bg-white focus:border-white active:bg-logga-300 active:border-logga-300",
  "colors_filled": "border-transparent bg-logga-500 text-white hover:bg-logga-600 focus:bg-logga-500 active:bg-logga-800",
  "colors_border": "border-logga-500 bg-transparent text-logga-500 hover:bg-logga-300 focus:bg-transparent active:bg-logga-400 active:border-logga-600",
  "size_default": "w-8 h-8",
  "variant_default": "inline-flex items-center justify-center transition-all duration-200 ease-in-out ring-0 border rounded-full focus:outline-none hover:ring-0 hover:outline-none active:ring-0 active:outline-none focus:ring-0 outline-none"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "SoMe");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;