import { getBrandBy } from '@/utils/getBrandBy';
import { getSite } from '@/utils/getSite';
import { mergeProps } from '@/utils/mergeProps';
import { Navigation } from '../Navigation';
import { NavigationSoMeProps } from '../Navigation.SoMe';

type SoMeMenu = { headline?: React.ReactNode; links: NavigationSoMeProps[] };

export const getNavigationSoMeMenu = ({ headline, links }: SoMeMenu) => {
  const slug = getSite();
  const { name } = getBrandBy({ slug }) ?? {};

  return (
    <Navigation.SoMe.Group>
      <Navigation.SoMe.Headline>{(headline ?? name) ? `Följ ${name}` : ''}</Navigation.SoMe.Headline>
      {links.map((link, index) => (
        <Navigation.SoMe
          key={index}
          {...mergeProps(
            {
              options: {
                colors: 'plain',
              },
            },
            link,
          )}
        />
      ))}
    </Navigation.SoMe.Group>
  );
};
